import React, { Component } from 'react';
import './App.css';
import Particles from 'react-tsparticles';
import magauin from './magauin.png';
import locale from './locale.json';
import jsonp from 'jsonp';

import a1 from "./1.jpg";
import a2 from "./2.jpg";
import a3 from "./3.jpg";

import s1 from "./icons/1.jpg";
import s2 from "./icons/2.jpg";
import s3 from "./icons/3.jpg";
import s4 from "./icons/4.jpg";
import s5 from "./icons/5.jpg";
import s6 from "./icons/6.jpg";
import s7 from "./icons/7.jpg";
import fizmat from './fizmat.png';
import int from './int.jpg';
import repub from './repub.jpg';
import state from './state.jpg';

const as = [
	a1,
	a2,
	a3
]

const startups = [
	{
		icon: s1,
		a: 128,
		url: "https://www.instagram.com/ithouse.kz/"
	},
	{
		icon: s4,
		a: 96,
		url: "https://www.instagram.com/cooking.looking/"
	},
	{
		icon: s2,
		a: 72,
		url: "https://www.instagram.com/smartboard.kz/"
	},
	{
		icon: s3,
		a: 64,
		url: "https://www.instagram.com/nisrobotics/"
	},
	{
		icon: s5,
		a: 48,
		url: "https://www.instagram.com/psforme/"
	},
	{
		icon: s6,
		a: 48,
		url: "https://www.instagram.com/beatshop_kz/"
	},
	{
		icon: s7,
		a: 48,
		url: "https://vk.com/infour_shop"
	}
]

class App extends Component {
	constructor() {
		super();
		this.state = {
			posts: [],
			lang: navigator.language === 'ru-RU' ? 0 : 1
		};
		this.changeLang = this.changeLang.bind(this);
		this.getPosts = this.getPosts.bind(this);
	}
	changeLang() {
		let lang = this.state.lang + 1;
		this.setState({ lang: lang > 1 ? 0 : 1 });
	}
	componentDidMount() {
		this.getPosts();
	}

	getPosts() {
		const url = 'https://api.vk.com/method/wall.get?owner_id=-104745917&access_token=1af5ae6c1af5ae6c1af5ae6c861a9317c111af51af5ae6c4127d7d157e84b79a7372631&v=5.81&count=10';
		jsonp(url, {}, (error, result) => {
			if (!error) {
				let posts = [];
				for (let i in result.response.items) {
					if (
						result.response.items[i].attachments &&
						result.response.items[i].attachments.length === 1 &&
						result.response.items[i].attachments[0].type === 'link'
					) {
						posts.push(result.response.items[i]);
					}
				}
				console.log(posts);
				this.setState({ posts });
			}
		});
	};

	render() {
		return (
			<div className="App">
				<div className="hero">
					<div className="container">
						<div onClick={this.changeLang} className="lang-select">
							{locale[(this.state.lang + 1) % 2].lang}
						</div>
						<div className="hero-text">
							<h5>{locale[this.state.lang].hello}</h5>
							<h1>{locale[this.state.lang].name}</h1>
							<p>{locale[this.state.lang].subtitle}</p>
							<a href="https://wa.me/77751555135" className="button">
								{locale[this.state.lang].contact}
							</a>
						</div>
					</div>
					<div className="back">
						<div className="back-half" />
						<img src={magauin} alt="Magauin Kuanysh" />
					</div>
				</div>
				<div className="section">
					<div className="container">
						<div className="about">
							<h2>{locale[this.state.lang].aboutMeTitle}</h2>
							<div className="divider" />
							<p>{locale[this.state.lang].aboutMe}</p>
							<div className="buttons">
								<a href="https://wa.me/77751555135" className="button fill">
									{locale[this.state.lang].contact}
								</a>
								<a
									href="https://docviewer.yandex.ru/view/671876499/?*=ft0Us0HRhf5RJvf1r6eYZC5TXTx7InVybCI6InlhLWRpc2stcHVibGljOi8vVldCdi9ud3BjelRxTjhoNHlmZUl0TkdSRUJiOXhsRjdxZ0k0eDhsVGV0cz0iLCJ0aXRsZSI6IlJlenl1bWVfS3VhbnlzaC5kb2N4IiwidWlkIjoiNjcxODc2NDk5IiwieXUiOiI1NjMzMTkxOTcxNTQxMzE2MjA3Iiwibm9pZnJhbWUiOmZhbHNlLCJ0cyI6MTU0Mjk2NjMyMjM5NH0%3D"
									className="button"
								>
									{locale[this.state.lang].cv}
								</a>
							</div>
						</div>
					</div>
				</div>
				<div className="section">
					<div className="container">
						<h2>{locale[this.state.lang].skillsTitle}</h2>
						<div className="divider" />
						<p>{locale[this.state.lang].skillsSubtitle}</p>
						<div className="grid">
							<div className="skill">
								<div className="icon">
									<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
										<path d="M480 288v-64h-34.7c-2-12.1-5.2-23.8-9.3-35l30-17.3-32-55.4-30 17.3c-7.7-9.3-16.3-17.9-25.6-25.6l17.3-30-55.4-32L323 76c-11.2-4.2-22.9-7.3-35-9.3V32h-64v34.7c-12.1 2-23.8 5.2-35 9.3l-17.3-30-55.4 32 17.3 30c-9.3 7.7-17.9 16.3-25.6 25.6l-30-17.3-32 55.4L76 189c-4.2 11.2-7.3 22.9-9.3 35H32v64h34.7c2 12.1 5.2 23.8 9.3 35l-30 17.3 32 55.4 30-17.3c7.7 9.3 16.3 17.9 25.6 25.6l-17.3 30 55.4 32 17.3-30c11.2 4.2 22.9 7.3 35 9.3V480h64v-34.7c12.1-2 23.8-5.2 35-9.3l17.3 30 55.4-32-17.3-30c9.3-7.7 17.9-16.3 25.6-25.6l30 17.3 32-55.4-30-17.3c4.2-11.2 7.3-22.9 9.3-35H480zm-224-64c17.7 0 32 14.3 32 32s-14.3 32-32 32-32-14.3-32-32 14.3-32 32-32zM141.2 343c-18.3-24.2-29.2-54.3-29.2-87 0-6.1.4-12.1 1.1-18l46.9 17.1v.9c0 17.8 4.9 34.5 13.3 48.8L141.2 343zm40.7-148L135 177.9c20.1-31.1 51.8-53.9 89-62.3v49.9c-16.6 5.9-31.1 16.2-42.1 29.5zM256 400c-23.7 0-46-5.7-65.8-15.9l32.1-38.2c10.5 3.9 21.8 6.1 33.7 6.1s23.2-2.2 33.7-6.1l32.1 38.2C302 394.3 279.7 400 256 400zm32-234.5v-49.9c37.2 8.4 68.9 31.2 89 62.3L330.1 195c-11-13.3-25.5-23.6-42.1-29.5zM370.8 343l-32.1-38.2c8.4-14.3 13.3-31 13.3-48.8v-.9l46.9-17.1c.7 5.9 1.1 11.9 1.1 18 0 32.7-10.9 62.8-29.2 87z" />
									</svg>
								</div>
								<h3>{locale[this.state.lang].skills[0].title}</h3>
								<p>{locale[this.state.lang].skills[0].subtitle}</p>
							</div>
							<div className="skill">
								<div className="icon">
									<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
										<path d="M190.4 354.1L91.9 256l98.4-98.1-30-29.9L32 256l128.4 128 30-29.9zm131.2 0L420 256l-98.4-98.1 30-29.9L480 256 351.6 384l-30-29.9z" />
									</svg>
								</div>
								<h3>{locale[this.state.lang].skills[1].title}</h3>
								<p>{locale[this.state.lang].skills[1].subtitle}</p>
							</div>
							<div className="skill">
								<div className="icon">
									<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
										<path d="M256 48C141.124 48 48 141.125 48 256s93.124 208 208 208c114.875 0 208-93.125 208-208S370.875 48 256 48zm-21.549 384.999c-39.464-4.726-75.978-22.392-104.519-50.932C96.258 348.393 77.714 303.622 77.714 256c0-42.87 15.036-83.424 42.601-115.659.71 8.517 2.463 17.648 2.014 24.175-1.64 23.795-3.988 38.687 9.94 58.762 5.426 7.819 6.759 19.028 9.4 28.078 2.583 8.854 12.902 13.498 20.019 18.953 14.359 11.009 28.096 23.805 43.322 33.494 10.049 6.395 16.326 9.576 13.383 21.839-2.367 9.862-3.028 15.937-8.13 24.723-1.557 2.681 5.877 19.918 8.351 22.392 7.498 7.497 14.938 14.375 23.111 21.125 12.671 10.469-1.231 24.072-7.274 39.117zm147.616-50.932c-25.633 25.633-57.699 42.486-92.556 49.081 4.94-12.216 13.736-23.07 21.895-29.362 7.097-5.476 15.986-16.009 19.693-24.352 3.704-8.332 8.611-15.555 13.577-23.217 7.065-10.899-17.419-27.336-25.353-30.781-17.854-7.751-31.294-18.21-47.161-29.375-11.305-7.954-34.257 4.154-47.02-1.417-17.481-7.633-31.883-20.896-47.078-32.339-15.68-11.809-14.922-25.576-14.922-42.997 12.282.453 29.754-3.399 37.908 6.478 2.573 3.117 11.42 17.042 17.342 12.094 4.838-4.043-3.585-20.249-5.212-24.059-5.005-11.715 11.404-16.284 19.803-24.228 10.96-10.364 34.47-26.618 32.612-34.047s-23.524-28.477-36.249-25.193c-1.907.492-18.697 18.097-21.941 20.859.086-5.746.172-11.491.26-17.237.055-3.628-6.768-7.352-6.451-9.692.8-5.914 17.262-16.647 21.357-21.357-2.869-1.793-12.659-10.202-15.622-8.968-7.174 2.99-15.276 5.05-22.45 8.039 0-2.488-.302-4.825-.662-7.133a176.585 176.585 0 0 1 45.31-13.152l14.084 5.66 9.944 11.801 9.924 10.233 8.675 2.795 13.779-12.995L282 87.929V79.59c27.25 3.958 52.984 14.124 75.522 29.8-4.032.361-8.463.954-13.462 1.59-2.065-1.22-4.714-1.774-6.965-2.623 6.531 14.042 13.343 27.89 20.264 41.746 7.393 14.801 23.793 30.677 26.673 46.301 3.394 18.416 1.039 35.144 2.896 56.811 1.788 20.865 23.524 44.572 23.524 44.572s10.037 3.419 18.384 2.228c-7.781 30.783-23.733 59.014-46.769 82.052z" />
									</svg>
								</div>
								<h3>{locale[this.state.lang].skills[2].title}</h3>
								<p>{locale[this.state.lang].skills[2].subtitle}</p>
							</div>
							<div className="skill">
								<div className="icon">
									<img src={fizmat}/>
								</div>
								<h3>{locale[this.state.lang].skills[3].title}</h3>
								<p>{locale[this.state.lang].skills[3].subtitle}</p>
							</div>
						</div>
					</div>
				</div>
				<div className="section">
					<div className="container">
						<h2>{locale[this.state.lang].myAchievements}</h2>
						<div className="divider" />
						<a className="as-wrap" href="https://vk.com/album-104745917_258485625">
							{
								as.map((el, i) => (
									<img className="as" key={i} src={el} alt={`Achievement ${i}`} />
								))
							}
						</a>
					</div>
				</div>
				<div className="section">
					<div className="container">
						<h2>{locale[this.state.lang].achievementsTitle}</h2>
						<div className="divider" />
						<p>{locale[this.state.lang].achievementsSubtitle}</p>
						<div className="photos">
							<a
								target="_blank"
								rel="noopener noreferrer"
								href="https://vk.com/album-104745917_242904549"
								style={{ backgroundImage: 'url(' + int + ')' }}
								className="album"
							>
								<div>
									<h4>{locale[this.state.lang].int}</h4>
									<span>28</span>
								</div>
							</a>
							<a
								target="_blank"
								rel="noopener noreferrer"
								href="https://vk.com/album-104745917_242904578"
								style={{ backgroundImage: 'url(' + repub + ')' }}
								className="album"
							>
								<div>
									<h4>{locale[this.state.lang].repub}</h4>
									<span>29</span>
								</div>
							</a>
							<a
								target="_blank"
								rel="noopener noreferrer"
								href="https://vk.com/album-104745917_242904579"
								style={{ backgroundImage: 'url(' + state + ')' }}
								className="album"
							>
								<div>
									<h4>{locale[this.state.lang].state}</h4>
									<span>47</span>
								</div>
							</a>
						</div>
					</div>
				</div>
				<div className="section">
					<div className="container">
						<h2>{locale[this.state.lang].myStartups}</h2>
						<div className="divider" />
						<div className="startups">
							{
								startups.map((el, i) => (
									<Movable className={`startup startup-${i + 1}`} maxX={el.a} maxY={el.a} key={i}><a href={el.url} style={{ backgroundImage: `url(${el.icon})` }} /></Movable>
								))
							}
						</div>
					</div>
				</div>
				<div className="section">
					<div className="container">
						<h2>{locale[this.state.lang].newsTitle}</h2>
						<div className="divider" />
						<p>{locale[this.state.lang].newsSubtitle}</p>
						{this.state.posts.length ? (
							<div className="grid">
								{this.state.posts.map((post, i) => {
									const sizes = post.attachments[0].link.photo.sizes;
									return (
										<a
											href={post.attachments[0].link.url}
											style={{
												backgroundImage: 'url(' + sizes[sizes.length - 1].url + ')'
											}}
											className="post"
											key={i}
										>
											<div className="post-title">
												<div className="post-title__text">{post.attachments[0].link.title}</div>
												<button className="post-title__button">
													{locale[this.state.lang].read}
												</button>
											</div>
	
											<div className="post-fade" />
										</a>
									);
								})}
							</div>
						) : (
								<div className="empty">{locale[this.state.lang].empty}</div>
							)}
					</div>
				</div>
				<div className="section">
					<div className="container">
						<h2>{locale[this.state.lang].expTitle}</h2>
						<div className="divider" />
						<p>{locale[this.state.lang].expSubtitle}</p>
						<div className="exp">
							{locale[this.state.lang].expItems.map((el, i) => (
								<div key={i} className="exp-item">
									<h5>{el.time}</h5>
									<h3>{el.position}</h3>
									<h5>{el.place}</h5>
								</div>
							))}
						</div>
					</div>
				</div>
				<div className="end">
					<div className="container">
						<h1>{locale[this.state.lang].wannaWork}</h1>
						<div className="divider" />
						<a href="https://wa.me/77751555135" className="button white fill">
							{locale[this.state.lang].contact}
						</a>
					</div>
					<Particles
						options={{
							particles: {
								number: {
									value: window.innerWidth > 768 ? 80 : 20,
									density: { enable: true, value_area: 800 }
								},
								color: { value: '#ffffff' },
								shape: {
									type: 'circle',
									stroke: { width: 0, color: '#000000' },
									polygon: { nb_sides: 5 },
									image: { src: 'img/github.svg', width: 100, height: 100 }
								},
								opacity: {
									value: 0.5,
									random: false,
									anim: { enable: false, speed: 1, opacity_min: 0.1, sync: false }
								},
								size: {
									value: 3,
									random: true,
									anim: { enable: false, speed: 40, size_min: 0.1, sync: false }
								},
								line_linked: { enable: true, distance: 150, color: '#ffffff', opacity: 0.4, width: 1 },
								move: {
									enable: true,
									speed: 6,
									direction: 'none',
									random: false,
									straight: false,
									out_mode: 'out',
									bounce: false,
									attract: { enable: false, rotateX: 600, rotateY: 1200 }
								}
							},
							interactivity: {
								detect_on: 'canvas',
								events: {
									onhover: { enable: true, mode: 'repulse' },
									onclick: { enable: true, mode: 'push' },
									resize: true
								},
								modes: {
									grab: { distance: 400, line_linked: { opacity: 1 } },
									bubble: { distance: 400, size: 40, duration: 2, opacity: 8, speed: 3 },
									repulse: { distance: 200, duration: 0.4 },
									push: { particles_nb: 4 },
									remove: { particles_nb: 2 }
								}
							},
							retina_detect: true
						}}
						width="100%"
						height="100%"
						className="particles"
					/>
				</div>
				<div className="social">
					<div className="container">
						<p>{locale[this.state.lang].social}</p>
						<div className="links">
							<a
								target="_blank"
								href="https://vk.com/phoenixfromhell"
								rel="noopener noreferrer"
								className="social-link"
							>
								<svg fill="#fff" role="img" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
									<path d="M11.701 18.771h1.437s.433-.047.654-.284c.21-.221.21-.63.21-.63s-.031-1.927.869-2.21c.887-.281 2.012 1.86 3.211 2.683.916.629 1.605.494 1.605.494l3.211-.044s1.682-.105.887-1.426c-.061-.105-.451-.975-2.371-2.76-2.012-1.861-1.742-1.561.676-4.787 1.469-1.965 2.07-3.166 1.875-3.676-.166-.48-1.26-.361-1.26-.361l-3.602.031s-.27-.031-.465.09c-.195.119-.314.391-.314.391s-.572 1.529-1.336 2.82c-1.623 2.729-2.268 2.879-2.523 2.699-.604-.391-.449-1.58-.449-2.432 0-2.641.404-3.75-.781-4.035-.39-.091-.681-.15-1.685-.166-1.29-.014-2.378.01-2.995.311-.405.203-.72.652-.539.675.24.03.779.146 1.064.537.375.506.359 1.636.359 1.636s.211 3.116-.494 3.503c-.495.262-1.155-.28-2.595-2.756-.735-1.26-1.291-2.67-1.291-2.67s-.105-.256-.299-.406c-.227-.165-.557-.225-.557-.225l-3.435.03s-.51.016-.689.24c-.166.195-.016.615-.016.615s2.686 6.287 5.732 9.453c2.79 2.902 5.956 2.715 5.956 2.715l-.05-.055z" />
								</svg>
							</a>
							<a
								target="_blank"
								rel="noopener noreferrer"
								href="https://www.instagram.com/phoenixfromhell/"
								className="social-link"
							>
								<svg fill="#fff" role="img" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
									<path d="M12 0C8.74 0 8.333.015 7.053.072 5.775.132 4.905.333 4.14.63c-.789.306-1.459.717-2.126 1.384S.935 3.35.63 4.14C.333 4.905.131 5.775.072 7.053.012 8.333 0 8.74 0 12s.015 3.667.072 4.947c.06 1.277.261 2.148.558 2.913.306.788.717 1.459 1.384 2.126.667.666 1.336 1.079 2.126 1.384.766.296 1.636.499 2.913.558C8.333 23.988 8.74 24 12 24s3.667-.015 4.947-.072c1.277-.06 2.148-.262 2.913-.558.788-.306 1.459-.718 2.126-1.384.666-.667 1.079-1.335 1.384-2.126.296-.765.499-1.636.558-2.913.06-1.28.072-1.687.072-4.947s-.015-3.667-.072-4.947c-.06-1.277-.262-2.149-.558-2.913-.306-.789-.718-1.459-1.384-2.126C21.319 1.347 20.651.935 19.86.63c-.765-.297-1.636-.499-2.913-.558C15.667.012 15.26 0 12 0zm0 2.16c3.203 0 3.585.016 4.85.071 1.17.055 1.805.249 2.227.415.562.217.96.477 1.382.896.419.42.679.819.896 1.381.164.422.36 1.057.413 2.227.057 1.266.07 1.646.07 4.85s-.015 3.585-.074 4.85c-.061 1.17-.256 1.805-.421 2.227-.224.562-.479.96-.899 1.382-.419.419-.824.679-1.38.896-.42.164-1.065.36-2.235.413-1.274.057-1.649.07-4.859.07-3.211 0-3.586-.015-4.859-.074-1.171-.061-1.816-.256-2.236-.421-.569-.224-.96-.479-1.379-.899-.421-.419-.69-.824-.9-1.38-.165-.42-.359-1.065-.42-2.235-.045-1.26-.061-1.649-.061-4.844 0-3.196.016-3.586.061-4.861.061-1.17.255-1.814.42-2.234.21-.57.479-.96.9-1.381.419-.419.81-.689 1.379-.898.42-.166 1.051-.361 2.221-.421 1.275-.045 1.65-.06 4.859-.06l.045.03zm0 3.678c-3.405 0-6.162 2.76-6.162 6.162 0 3.405 2.76 6.162 6.162 6.162 3.405 0 6.162-2.76 6.162-6.162 0-3.405-2.76-6.162-6.162-6.162zM12 16c-2.21 0-4-1.79-4-4s1.79-4 4-4 4 1.79 4 4-1.79 4-4 4zm7.846-10.405c0 .795-.646 1.44-1.44 1.44-.795 0-1.44-.646-1.44-1.44 0-.794.646-1.439 1.44-1.439.793-.001 1.44.645 1.44 1.439z" />
								</svg>
							</a>
							<a
								target="_blank"
								rel="noopener noreferrer"
								href="https://www.facebook.com/profile.php?id=100001691275491"
								className="social-link"
							>
								<svg fill="#fff" role="img" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
									<path d="M22.676 0H1.324C.593 0 0 .593 0 1.324v21.352C0 23.408.593 24 1.324 24h11.494v-9.294H9.689v-3.621h3.129V8.41c0-3.099 1.894-4.785 4.659-4.785 1.325 0 2.464.097 2.796.141v3.24h-1.921c-1.5 0-1.792.721-1.792 1.771v2.311h3.584l-.465 3.63H16.56V24h6.115c.733 0 1.325-.592 1.325-1.324V1.324C24 .593 23.408 0 22.676 0" />
								</svg>
							</a>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

class Movable extends Component {
	constructor(props) {
		super(props)
		this.state = {
			offsetX: 0,
			offsetY: 0,
			rect: {}
		}
		this.wait = false
		this.calculateMove = this.calculateMove.bind(this)
		this.start = this.start.bind(this)
		this.addListeners = this.addListeners.bind(this)
		this.removeListeners = this.removeListeners.bind(this)
		this.reset = this.reset.bind(this)
		this.resize = this.resize.bind(this)
	}
	componentDidMount() {
		this.setState({
			rect: this.refs.wrap.getBoundingClientRect()
		})
		window.addEventListener('resize', this.resize)
		window.addEventListener("scroll", this.resize)
		if (window.innerWidth > 768) {
			this.addListeners()
		}
	}
	componentWillUnmount() {
		window.removeEventListener('resize', this.resize)
		window.removeEventListener("scroll", this.resize)
	}
	addListeners() {
		this.refs.wrap.addEventListener('mouseenter', this.start)
		this.refs.wrap.addEventListener('mousemove', this.calculateMove)
		this.refs.wrap.addEventListener('mouseleave', this.reset)
	}
	removeListeners() {
		this.refs.wrap.removeEventListener('mouseenter', this.start)
		this.refs.wrap.removeEventListener('mousemove', this.calculateMove)
		this.refs.wrap.removeEventListener('mouseleave', this.reset)
	}
	resize() {
		if (window.innerWidth < 768) {
			this.removeListeners()
		} else {
			this.addListeners()
		}
		this.setState({
			rect: this.refs.wrap.getBoundingClientRect()
		})
	}
	calculateMove(e) {
		let alphaX = this.state.rect.left + (this.state.rect.width / 2) - e.clientX
		let alphaY = this.state.rect.top + (this.state.rect.height / 2) - e.clientY
		if (Math.abs(alphaX) > this.props.maxX || Math.abs(alphaY) > this.props.maxY) {
			this.reset()
			return
		}
		this.setState({
			offsetX: -alphaX,
			offsetY: -alphaY
		})
	}
	start() {
		this.props.onStart && this.props.onStart()
	}
	reset() {
		this.removeListeners()
		this.setState({
			offsetX: 0,
			offsetY: 0,
			reset: true
		})
		this.props.onReset && this.props.onReset()
		this.addListeners()
		setTimeout(() => {
			this.addListeners()
			this.setState({
				reset: false
			})
		}, 200)
	}
	render() {
		return (
			<div
				ref="wrap"
				style={
					{
						transform: 'matrix(1,0,0,1,' + this.state.offsetX + ',' + this.state.offsetY + ')',
						transition: this.state.reset ? 'transform .3s ease-in-out' : 'none'
					}
				} className={this.props.className ? this.props.className + ' Movable' : 'Movable'}>
				{this.props.children}
			</div>
		)
	}
}


export default App;
